import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoicingExpenseDialogComponent } from 'src/shared/components/dialog/admin-dialogs/invoicing-expense-dialog/invoicing-expense-dialog.component';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { takeUntil } from 'rxjs';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  convertCurrencyToWords,
  convertNumberToWords,
} from 'src/app/utils/number-to-words.util';
import { InvoicingService } from '../../services/invoicing.service';
import { InvoiceActivityComponent } from './invoicing-activity-log/invoicing-activity-log.component';
import { PaymentReceivedDialogComponent } from 'src/shared/components/dialog/admin-dialogs/payment-received-dialog/payment-received-dialog.component';
@Component({
  selector: 'app-invoicing-preview',
  templateUrl: './invoicing-preview.component.html',
})
export class InvoicingPreviewComponent implements OnInit {
  id!: string;
  isSidebarOpen = false;
  isSidepanelOpen = false;
  isDrawerOpen = false;
  drawerWidth = 200;
  isActive = false;
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  invoicingItemData: any = {};
  loading: boolean = false;
  totalInWords: string = '';
  currency: string = '';
  bankDetails: any = {};
  canManageResources: boolean = false;

  @ViewChild('invoiceActivityLog')
  invoiceActivityLog!: InvoiceActivityComponent;

  openActivityLogDrawer(): void {
    if (this.invoiceActivityLog) {
      this.invoiceActivityLog.isOpen = !this.invoiceActivityLog.isOpen;
    }
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private invoicingService: InvoicingService,
    private globalService: GlobalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const permissions = localStorage.getItem('modules');
    if (permissions) {
      const userPermissions = JSON.parse(permissions);
      const cost_centerPermissions = userPermissions.find(
        (p: any) => p.module === 'invoice'
      );
      if (
        cost_centerPermissions &&
        cost_centerPermissions.permission.includes('view') &&
        cost_centerPermissions.permission.includes('manage')
      ) {
        this.canManageResources = true;
      }
    }

    this.id = this.route.snapshot.params['id'];
    this.globalService.setInvoiceId(this.id);
    this.getInvoicingItemData();
    this.getBankDetail();
  }

  statusMapping: { [key: string]: string } = {
    off_board: 'draft',
    Void: 'void',
    Sent: 'sent',
  };

  onPaymentStatusChange(event: any): void {
    const selectedValue = event.value;
    const invoiceStatus = this.statusMapping[selectedValue] || '';

    if (selectedValue === 'active') {
      this.openPaymentReceivedDialog();
    } else if (invoiceStatus) {
      this.updateStatusData(invoiceStatus);
    }
    event.source.value = null;
  }

  openPaymentReceivedDialog() {
    const dialogRef = this.dialog.open(PaymentReceivedDialogComponent, {
      height: 'auto',
      width: ' 560px',
      data: { invoiceID: this.id, invoiceItemData: this.invoicingItemData },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.invoicingItemData(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      }
    });
  }

  generatePDF() {
    const element = document.querySelector(
      '.invoice-box'
    ) as HTMLElement | null;
    if (element) {
      const scale = 2;
      const options = {
        scale: scale,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      };

      html2canvas(element, options).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight,'','FAST');
        pdf.save('invoice.pdf');
      });
    } else {
      console.error('Element not found');
    }
  }

  getInvoicingItemData(): void {
    this.loading = true;
    this.invoicingService
      .getInvoiceItemListById(this.id)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingItemData = data?.data;
          this.loading = false;
          this.totalInWords =
            convertCurrencyToWords(
              this.invoicingItemData?.account?.financials[0]?.currency
            ) +
            ' ' +
            convertNumberToWords(
              this.invoicingItemData.total,
              this.invoicingItemData?.account?.financials[0]?.currency
            );
          this.currency =
            this.invoicingItemData?.account?.financials[0]?.currency;
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  updateStatusData(status: string): void {
    const statusData = {
      invoiceID: this.id,
      invoiceStatus: status,
    };

    this.invoicingService
      .updatePaymentStatus(statusData)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in updating Status:', error);
          this.loading = false;
        },
      });
  }

  getBankDetail(): void {
    this.loading = true;
    this.invoicingService
      .getBankDetails()
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.bankDetails = data?.data;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in fetching Bank Details:', error);
          this.loading = false;
        },
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(InvoicingExpenseDialogComponent, {
      height: 'auto',
      width: '467px',
      data: {
        isEditMode: false,
        currency: this.currency,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getInvoicingItemData();
      }
    });
  }

  openEditDialog(item: any): void {
    const dialogRef = this.dialog.open(InvoicingExpenseDialogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: true, item: item, currency: this.currency },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getInvoicingItemData();
      }
    });
  }

  goBack() {
    this.router.navigateByUrl('/invoicing');
  }

  getCurrencySymbol(currencyCode: string): string {
    switch (currencyCode) {
      case 'INR':
        return '₹';
      case 'USD':
        return '$';
      case 'AUD':
        return '$';
      default:
        return '';
    }
  }
}
