import { NgModule } from '@angular/core';
import { AngularMaterialModule } from '../../../../shared/modules/angular-material/angular-material.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SftButtonModule } from '../../../../stories/components/button/button.module';
import { SftInputModule } from '../../../../stories/components/Input/input.module';
import { SftSwitchButtonModule } from '../../../../stories/components/switch/switch.module';
import { CommonModule } from '@angular/common';
import { StepperComponent } from '../../../../stories/components/stepper/stepper.component';
import { LayoutModule } from '../../core/layouts/layout/layout.module';
import { SftRadioButtonModule } from '../../../../stories/components/radiobutton/radio.module';
import { SharedModule } from 'src/shared/modules/shared/shared.module';
import { RouterModule } from '@angular/router';
import { InvoicingComponent } from './components/invoicing/invoicing.component';
import { InvoicingRoutingModule } from './invoicing-routing.module';
import { InvoicingPreviewComponent } from './components/invoicing-preview/invoicing-preview.component';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { FilterInvoicingSidebarComponent } from './components/invoicing/filter-sidebar/filter-invoicing-sidebar-component';
import { InvoiceActivityComponent } from './components/invoicing-preview/invoicing-activity-log/invoicing-activity-log.component';

@NgModule({
  declarations: [
    InvoicingComponent,
    InvoicingPreviewComponent,
    FilterInvoicingSidebarComponent,
    InvoiceActivityComponent,
  ],
  imports: [
    LayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    AngularMaterialModule,
    LoaderComponent,
    SidebarModule,
    SftButtonModule,
    SftInputModule,
    SftSwitchButtonModule,
    SftRadioButtonModule,
    StepperComponent,
    CommonModule,
    SharedModule,
    RouterModule,
    InvoicingRoutingModule,
  ],
})
export class InvoicingModule {}
