<div class="main-content body-bg listing-wrapper invoice-listing">
  <div class="p-6">
    <div class="d-flex justify-between flex-wrap align-center mb-4">
      <div>
        <h4 class="mat-headline-5 strong text mb-1">Invoicing</h4>
        <span class="textXS text">Manage Your Invoice</span>
      </div>
      <div class="d-flex align-center inputs-wrapper">
        <mat-form-field
          appearance="outline"
          class="search-input mr-0"
          [formGroup]="form"
        >
          <input
            type="text"
            matInput
            placeholder="Search"
            formControlName="searchQuery"
            (keydown.enter)="$event.preventDefault()"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div class="d-flex ml-2 justify-between header-info-wrapper">
          <button
            mat-button
            class="outline-button"
            (click)="openFilterDrawer()"
          >
            <img src="/images/filter.png" alt="" class="mr-2" />
            <div class="d-flex align-center">
              <span class="mr-2">Filter</span>
              <div *ngIf="isFilterApplied" class="active-circle"></div>
              </div>
          </button>
        </div>
      </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <div
      *ngIf="!loading && invoicingList.length === 0"
      class="required-star"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 200px);
        font-size: 20px;
      "
    >
      No invoices found!
    </div>
    <div
      class="body-bg listing-content overflow-auto craft-listing admin-invoicing-listing"
      *ngIf="invoicingList.length > 0"
    >
      <table
        *ngIf="!loading && invoicingList.length > 0"
        mat-table
        [dataSource]="invoicingList"
        matSort
        (matSortChange)="onSort($event)"
        class="custom-action-table mt-0"
        style="cursor: pointer"
      >
        <ng-container matColumnDef="invoice">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="invoiceNumber"
            class="invoice-no text textS"
          >
            Invoice No.
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/invoicing/view/' + element.invoiceID"
          >
            <div class="d-flex align-center">
              <span class="grey-text textS invoice-no-col">{{
                element?.invoice_number
              }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="title"
            class="invoice-title text textS"
          >
            Invoice title
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/invoicing/view/' + element.invoiceID"
            title="{{ element?.subject || '--' }}"
          >
            <div class="d-flex align-center">
              <span class="grey-text textS title-col table-ellipse">{{
                element?.subject || "--"
              }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="type"
            class="invoice-type text textS"
          >
            Invoice Type
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text textS invoice-type-col"
            [routerLink]="'/invoicing/view/' + element.invoiceID"
            title=" {{
              element?.type === 'operational_cost'
                ? 'Operational Cost'
                : element?.type === 'core_team'
                ? 'Core Team'
                : element?.type === 'project_hourly'
                ? 'Project Hourly'
                : element?.type === 'project_fixed'
                ? 'Project Fixed'
                : element?.type === 'project_daily'
                ? 'Project Daily'
                : element?.type === 'operational_core_team'
                ? 'Core Team+Operational'
                : element?.type
            }}"
          >
            {{
              element?.type === "operational_cost"
                ? "Operational Cost"
                : element?.type === "core_team"
                ? "Core Team"
                : element?.type === "project_hourly"
                ? "Project Hourly"
                : element?.type === "project_fixed"
                ? "Project Fixed"
                : element?.type === "project_daily"
                ? "Project Daily"
                : element?.type === "operational_core_team"
                ? "Core Team+Operational"
                : element?.type
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="account">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="accountName"
            class="invoice-type text textS"
          >
            Accounts
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text textS invoice-type-col"
            [routerLink]="'/invoicing/view/' + element.invoiceID"
            title="  {{ element?.name || '--' }}"
          >
            {{ element?.name || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="costCenter">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="centerName"
            class="cost-centre text textS invoice-no"
          >
            Cost Center
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text textS invoice-type-col"
            [routerLink]="'/invoicing/view/' + element.invoiceID"
            title="{{ element?.center_name || '--' }}"
          >
            {{ element?.center_name || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="month">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="createdAt"
            class="notice-period text textS"
          >
            Month
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text textS"
            [routerLink]="'/invoicing/view/' + element.invoiceID"
          >
            {{
              element?.invoice_date
                ? (element?.invoice_date | date : "MMMM")
                : "--"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="total"
            class="amount text textS"
          >
            Amount
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text textS amount-col"
            [routerLink]="'/invoicing/view/' + element.invoiceID"
          >
            {{ element?.total || "--" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="invoiceStatus"
            class="status"
          >
            Status
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text textXS"
            [routerLink]="'/invoicing/view/' + element.invoiceID"
          >
            <div
              class="candidate-status justify-center d-flex align-center textXS fw-500"
              [ngClass]="{
                'warning-light-bg warning-text':
                  element.invoice_status === 'received',
                'success-text success-light-bg':
                  element.invoice_status === 'sent',
                'info-text  info-light-bg': element.invoice_status === 'draft'
              }"
            >
              {{
                element?.invoice_status
                  ? getFormattedStatus(element?.invoice_status)
                  : "--"
              }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="lastUpdated">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="modifiedAt"
            class="updated text textS"
          >
            Last Updated
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text textS ls-normal"
            [routerLink]="'/invoicing/view/' + element.invoiceID"
          >
            {{ element?.modified_at | date : "d MMM y h:mm a" : "UTC" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="action text textS">
            Action
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="text-center textS grey-text"
          >
            <mat-icon
              [matMenuTriggerFor]="menuEdit"
              (click)="setElement(element)"
            >
              more_vert
            </mat-icon>
          </td>
          <mat-menu #menuEdit="matMenu" class="action-info">
            <button
              [style.cursor]="!canManageResources ? 'not-allowed' : 'pointer'"
              mat-menu-item
              (click)="onEditClick(element.invoiceID)"
              [disabled]="!canManageResources"
              [matTooltip]="
                canManageResources
                  ? ''
                  : 'You do not have permission to edit invoice'
              "
            >
              Edit
            </button>
            <!-- <button mat-menu-item>Download</button> -->
            <!-- <button mat-menu-item (click)="togglePaymentStatus()">
              {{ isPaid ? "Mark as Unpaid" : "Mark as Paid" }}
            </button>
            <button mat-menu-item>Activity Log</button> -->
          </mat-menu>
        </ng-container>
        <tr
          mat-header-row
          class="action-table-header-data"
          *matHeaderRowDef="displayedInvoicingColumns"
        ></tr>
        <tr
          mat-row
          class="action-table-body-data"
          *matRowDef="let row; columns: displayedInvoicingColumns"
        ></tr>
      </table>
      <mat-paginator
        class="action-table-pagination fixed bottom-0 invoicing"
        [length]="totalRecords"
        [pageSize]="limit"
        [pageIndex]="pageNumber"
        [pageSizeOptions]="[25, 50, 75, 100]"
        aria-label="Select page"
        (page)="onPageChange($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
<app-invoicing-admin-dialog-filter
  #filter
  (filtersApplied)="handleFilterApplied($event)"
></app-invoicing-admin-dialog-filter>
