import { Component } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { FormGroup } from '@angular/forms';
import { InvoicingService } from '../../../services/invoicing.service';

interface ActivityLog {
  logID: string;
  timestamp: string;
  moduleID: string;
  moduleType: string;
  activityType: string;
  activityDetail: string;
  user: {
    name: string;
  };
}

@Component({
  selector: 'app-invoicing-activity-detail',
  templateUrl: './invoicing-activity-log.component.html',
})
export class InvoiceActivityComponent {
  side!: MatDrawerMode;
  isOpen: boolean = false;
  loading: boolean = false;
  activityPageNumber: number = 0;
  activityPageSize: number = 25;
  search: string = '';
  activityLogsGrouped: { date: string; logs: ActivityLog[] }[] = [];
  totalActivityRecords: number = 0;
  activityForm!: FormGroup;

  constructor(private invoicingService: InvoicingService) {}

  displayedColumns: string[] = ['date', 'name', 'account', 'creation'];
  dataSource!: ActivityLog[];

  ngOnInit() {
    this.fetchActivityLogs();
  }

  fetchActivityLogs(): void {
    this.loading = true;
    this.invoicingService
      .getActivityLogs(
        this.activityPageNumber,
        this.activityPageSize,
        this.search,
        'timestamp',
        'DESC'
      )
      .subscribe((res) => {
        const logs: ActivityLog[] = res.data.records;
        this.totalActivityRecords = res?.data?.count || 0;

        // Group logs by date
        const groupedLogs: { [date: string]: ActivityLog[] } = {};
        logs.forEach((log) => {
          const date = new Date(log.timestamp).toDateString();
          if (!groupedLogs[date]) {
            groupedLogs[date] = [];
          }
          groupedLogs[date].push(log);
        });

        this.activityLogsGrouped = Object.entries(groupedLogs).map(
          ([date, logs]) => ({
            date,
            logs,
          })
        );
        this.loading = false;
      });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (this.isToday(date)) {
      return `Todays - ${this.formatDateToString(date)}`;
    } else if (this.isYesterday(date)) {
      return `Yesterday - ${this.formatDateToString(date)}`;
    } else {
      return this.formatDateToString(date);
    }
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  isYesterday(date: Date): boolean {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }

  private formatDateToString(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return date.toLocaleDateString('en-US', options);
  }

  getDefaultPhotoText(name: string): string {
    if (!name) return '';

    const names = name.trim().split(/\s+/);
    let firstChar = '';
    let secondChar = '';

    if (names.length > 0) {
      firstChar = names[0].charAt(0);
      if (names.length > 1) {
        secondChar = names[1].charAt(0);
      }
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  activityTypeMap: { [key: string]: string } = {
    invoice_create: 'Invoice Created',
    invoice_update: 'Invoice Updated',
    invoice_update_status: 'Invoice Status Updated',
    invoice_item_create: 'Invoice Item Created',
  };

  getActivityTypeDisplayText(activityType: string): string {
    return this.activityTypeMap[activityType] || activityType;
  }
}
